* {
	box-sizing: border-box;
}

html,
body {
	overflow: hidden;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: 'Avenir', Helvetica, sans-serif;
}

#game {
	position: relative;
	width: 100%;
	height: 100%;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

#main-canvas {
	width: 100%;
	height: 100%;
}

#ui-canvas {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.tooltip,
.popover {
	z-index: 9999;
}

button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

::-webkit-scrollbar {
	width: 10px;
	height: 15px;
}

::-webkit-scrollbar-thumb {
	background: -webkit-linear-gradient(0deg, #ff8542, #ffd35d);
	border-radius: 20px;
}

::-webkit-scrollbar-track {
	background: #0a1f4c;
	border-radius: 20px;
}

.placeholder-ui-badge {
	position: absolute;
	transform: rotate(-25deg);
	top: 10px;
	left: -35px;
	font-family: 'Do Hyeon';
	font-size: 32px;
	font-weight: 1000;
	text-shadow: 0 0 10px black, 0 0 10px black, 0 0 30px white;
	color: #00ffc4;
}

.tooltip {
	display: block !important;
	z-index: 10000;

	min-width: 200px;
	position: fixed;
	background-color: #e9e5ad;
	border-radius: 1px;
	padding: 10px 10px;
	box-shadow: 5px 5px 5px #000;
	z-index: 99999999;
	color: #1b2d6e;
	color: rgba(#004499, 0.9);

	.tooltip-title {
		//text-transform: uppercase;
		text-align: center;
		font-weight: bold;
		padding: 0;
		margin: 0;
		font-size: 26px;
		font-family: 'Do Hyeon', sans-serif;
		font-variant: small-caps;
	}

	.tooltip-inner {
		border-radius: 16px;
		padding: 5px 10px 4px;
		background: #e9e5ad;
		color: rgba(#004499, 0.9);
		border-radius: 1px;
		box-shadow: 0 5px 30px rgba(black, 0.1);
		max-width: 250px;
		font-size: 19px;
		padding: 0;
		margin: 0;
		font-family: 'Yanone Kaffeesatz', 'Do Hyeon', sans-serif;
		text-align: center;
		font-weight: 400;
	}

	.tooltip-arrow {
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		margin: 5px;
		border-color: black;
		z-index: 1;
		border-color: #e9e5ad;
	}

	&[x-placement^='top'] {
		margin-bottom: 5px;

		.tooltip-arrow {
			border-width: 5px 5px 0 5px;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			bottom: -5px;
			left: calc(50% - 5px);
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^='bottom'] {
		margin-top: 5px;

		.tooltip-arrow {
			border-width: 0 5px 5px 5px;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			border-top-color: transparent !important;
			top: -5px;
			left: calc(50% - 5px);
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^='right'] {
		margin-left: 5px;

		.tooltip-arrow {
			border-width: 5px 5px 5px 0;
			border-left-color: transparent !important;
			border-top-color: transparent !important;
			border-bottom-color: transparent !important;
			left: -5px;
			top: calc(50% - 5px);
			margin-left: 0;
			margin-right: 0;
		}
	}

	&[x-placement^='left'] {
		margin-right: 5px;

		.tooltip-arrow {
			border-width: 5px 0 5px 5px;
			border-top-color: transparent !important;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			right: -5px;
			top: calc(50% - 5px);
			margin-left: 0;
			margin-right: 0;
		}
	}

	&[aria-hidden='true'] {
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.15s, visibility 0.15s;
	}

	&[aria-hidden='false'] {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.15s;
	}
}

.ftue-wrapper {
	width: 544px;
	height: 380px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	margin-right: 15px;
	flex-flow: column;

	img {
		width: 540px;
	}

	h1 {
		margin: 0.1em;
		font-size: 1.5em;
	}

	.ftue-flex {
		max-height: 320px;
		max-width: 530px;
		align-self: center;
		position: relative;

		img {
			border: 14px solid #555;
			border-image: url('../hud/assets/gear-skill-slot-a-trans.png') 10 6 10 6 fill / 17px 16px 17px 16px;
		}
	}

	.ftue-flex:nth-child(even) {
		flex: 2;
	}
	.ftue-flex:nth-child(odd) {
		flex: 3;
	}
}

.ftue {
	&.floated-image-row {
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
		}

		h1 {
			font-size: 2em;
		}
	}

	&.placeholder {
		margin: 10px;
		width: 200px;
		height: 110px;
		// background-image: url("../../../assets/ui-assets/ftue/ftue-placeholder.jpg");
	}

	&.text-left {
		text-align: left;
	}
	&.text-right {
		text-align: right;
	}

	&.float-left,
	.float-left {
		float: left;
	}
	&.float-right,
	.float-right {
		float: right;
	}

	&.color {
		&.rust {
			color: #fa0095;
		}

		&.teal {
			color: #a6ff68;
		}

		&.gold {
			color: #ffb703;
		}
	}
}
strong {
	font-weight: 600;
	color: #e9e5ad;
}

.depart-prompt{
	&.gold {
		color: #ffb703;
	}
	&.strong {
		font-weight: 600;
		color: #e9e5ad;
	}
}

p {
	a {
		font-size: 25px;
		&:link {
			text-decoration: none;
			color: #446ef6;
		}

		/* visited link */
		&:visited {
			color: #cccccc;
		}

		/* mouse over link */
		&:hover {
			color: #fff;
		}

		/* selected link */
		&:active {
			color: #fff;
		}
	}
}


.loot-account-tab-icon:before { content: '\e800'; } /* '' */
.loot-arcane-focus-icon:before { content: '\e801'; } /* '' */
.loot-augment-full-icon:before { content: '\e802'; } /* '' */
.loot-augment-line-icon:before { content: '\e803'; } /* '' */
.loot-back-cosmetics-tab-icon:before { content: '\e804'; } /* '' */
.loot-bundle-tab-icon:before { content: '\e805'; } /* '' */
.loot-coin-icon:before { content: '\e806'; } /* '' */
.loot-consumables-tab-icon:before { content: '\e807'; } /* '' */
.loot-crossbow-icon:before { content: '\e808'; } /* '' */
.loot-face-cosmetics-tab-icon:before { content: '\e809'; } /* '' */
.loot-featured-tab-icon:before { content: '\e80a'; } /* '' */
.loot-fire-damage-icon:before { content: '\e80b'; } /* '' */
.loot-gear-belt-icon:before { content: '\e80c'; } /* '' */
.loot-gear-pendant-icon:before { content: '\e80d'; } /* '' */
.loot-weapons-tab-icon:before { content: '\e80e'; } /* '' */
.loot-gear-ring-icon:before { content: '\e80f'; } /* '' */
.loot-gear-trinket-icon:before { content: '\e810'; } /* '' */
.loot-generic-bag-icon:before { content: '\e811'; } /* '' */
.loot-generic-cooldown-icon:before { content: '\e812'; } /* '' */
.loot-generic-duration-icon:before { content: '\e813'; } /* '' */
.loot-generic-heart-icon:before { content: '\e814'; } /* '' */
.loot-generic-key-icon:before { content: '\e815'; } /* '' */
.loot-factions-aurum-icon-compound:before { content: '\e816'; } /* '' */
.loot-generic-rarity-shard-icon:before { content: '\e817'; } /* '' */
.loot-generic-scroll-icon:before { content: '\e818'; } /* '' */
.loot-generic-worm-icon:before { content: '\e81a'; } /* '' */
.loot-ice-damage-icon:before { content: '\e81b'; } /* '' */
.loot-lightning-damage-icon:before { content: '\e81c'; } /* '' */
.loot-pet-tab-icon:before { content: '\e81d'; } /* '' */
.loot-pfx-trails-tab-icon:before { content: '\e81e'; } /* '' */
.loot-physical-damage-icon:before { content: '\e81f'; } /* '' */
.loot-player-skin-tab-icon:before { content: '\e820'; } /* '' */
.loot-poison-damage-icon:before { content: '\e821'; } /* '' */
.loot-prism-scale-icon:before { content: '\e822'; } /* '' */
.loot-scythe-icon:before { content: '\e823'; } /* '' */
.loot-staff-icon:before { content: '\e824'; } /* '' */
.loot-sword-icon:before { content: '\e825'; } /* '' */
.loot-wand-icon:before { content: '\e826'; } /* '' */
.loot-factions-scions-icon-compound:before { content: '\e828'; } /* '' */
.loot-factions-neutral-icon:before { content: '\e82a'; } /* '' */
.loot-factions-iron-icon-compound:before { content: '\e82b'; } /* '' */

.notification-body{
	height: 60px;
	justify-content: flex-end;
	align-items: center;
	padding-left: 50px;
	padding-right: 40px;
}